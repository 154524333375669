/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
import { forwardRef, useContext, useEffect, useRef, useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
  Autocomplete,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  Paper,
  Switch,
  TextField,
  Tooltip,
} from "@mui/material";
import style from "../Styles/vehicleInfo.module.css";
import Grid from "@mui/material/Grid";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { CacheContext } from "../Context/cacheContext";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import GenericAutocompleteModal from "../Modal/GenericAutocompleteModal";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ModeIcon from "@mui/icons-material/Mode";
import { urlEnum } from "../Utils/urlEnum";
import User from "./Users/User";
import { Vocabulary } from "../Utils/Vocabulary";
import { vehicleModel } from "../Models/vehicleDetails";
import CompanyModal from "../Components/Settings/CompanyModal";
import {
  AutocompleteSchema,
  AutocompleteSchemaForAddress,
  AutocompleteSchemaForFirmaAchizitie,
  AutocompleteSchemaForTipTVA,
  schema,
} from "../Validators/vehicleValidators";

import { AUTODELRULATE_AD_LINK, TemplatesType } from "../Utils/Constants";
import {
  GenericTemplatesType,
  NUMBER_OF_DECIMALS,
  download,
  getBnrEuroRate,
  replaceMacro,
  isMobile,
  isEmpty,
} from "../Utils/utils";
import { VehicleContext } from "../Context/vehicleContext";
import {
  AutocompleteModel,
  AutocompleteModelForAddress,
  AutocompleteModelForFirmaAchizitie,
  AutocompleteModelForTipTVA,
  AutocompleteModelForVehicleModels,
  GenericAutocompleteModalModel,
} from "../Models/Models";
import { getData } from "../Services/getData";
import { useNavigate } from "react-router-dom";
import { europeanDate, shortEuropeanDate } from "../Utils/formatDate";
import { PurchaseTypes, VehiclesStatus } from "../Utils/autocompleteEnum";
import { NumericFormat } from "react-number-format";
import GenericTemplate from "./Templates/GenericTemplate";
import { toast } from "react-toastify";
import PostAdsIntegrationModal from "./VehicleDasboard/PostAdsIntegrationModal";

// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: 58 * 4.5 + 8,
//     },
//   },
//   getContentAnchorEl: null,
//   anchorOrigin: {
//     vertical: "bottom",
//     horizontal: "center",
//   },
//   transformOrigin: {
//     vertical: "top",
//     horizontal: "center",
//   },
//   variant: "menu",
// };

const initialGenericModalState = new GenericAutocompleteModalModel();

export default function VehicleMainInfo(props) {
  const cacheContext = useContext(CacheContext);
  const vehicleContext = useContext(VehicleContext);
  const [openModalUser, setOpenModalUser] = useState(false);
  const [openModalCompany, setOpenModalCompany] = useState(false);
  const [sortedModels, setSortedModels] = useState([]);
  const [dotari, setDotari] = useState();
  const [open, setOpen] = useState(false);

  const [brandId, setBrandId] = useState(
    new AutocompleteModelForVehicleModels()
  );
  const [genericAutocompleteModel, setGenericAutocompleteModel] = useState(
    initialGenericModalState
  );

  const [TVADeductibil, setTVADeductibil] = useState(0);
  const [TVARecovered, setTVARecovered] = useState(0);
  const [date, setDate] = useState({
    data_achizitie: null,
    data_receptie: null,
    data_vanzare: null,
  });

  const [modelCar, setModelCar] = useState(vehicleModel);
  const [siteLink, setSiteLink] = useState(null);
  //description
  const [descriptionTemplates, setDescriptionTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const navigate = useNavigate();
  const [bnrEuroExchangeRate, setBnrEuroExchangeRate] = useState(1);
  const {
    handleSubmit,
    control,
    setValue,
    formState,
    getValues,
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      ...vehicleContext.state.vehicle,
      user_achizitie_id:
        vehicleContext.state.vehicle.id === 0
          ? parseInt(localStorage.getItem("useId"))
          : vehicleContext.state.vehicle.user_achizitie_id,
      user_achizitie_r:
        vehicleContext.state.vehicle.id === 0
          ? cacheContext.cache.users.find(
              (user) => user.id === parseInt(localStorage.getItem("useId"))
            )
          : cacheContext.cache.users.find(
              (user) =>
                user.id === vehicleContext.state.vehicle.user_achizitie_id
            ),
    },
  });
  const formRef = useRef();
  const isIntermediere = watch("tip_achizitie_r");
  const [openIntegrationModal, setOpenIntegrationModal] = useState(false);

  /**
   *
   * @param {*} event
   * @param {*} reason
   */
  const handleClose = (event, reason) => {
    if (reason === "blur") {
      setOpen(false);
    }
  };

  const NumericFormatCustom = forwardRef(function NumericFormatCustom(
    props,
    ref
  ) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator=" "
        valueIsNumericString
      />
    );
  });

  /**
   *
   */
  useEffect(() => {
    reset(vehicleContext.state.vehicle);
  }, [vehicleContext.state.vehicle]);

  /**
   *
   */
  useEffect(() => {
    setModels(formState.defaultValues?.brand_id, cacheContext.cache?.models);
    if (!vehicleContext?.state?.vehicle?.id) {
      getBnrEuroRate().then((exchangeRate) => {
        setBnrEuroExchangeRate(
          parseFloat(
            parseFloat(exchangeRate.toString()).toFixed(NUMBER_OF_DECIMALS)
          )
        );
      });
    }
    //aduce toate sabloanele pentru descriere
    getDescriptionTemplates();
  }, []);

  /**
   *
   */
  useEffect(() => {
    if (genericAutocompleteModel.id === "model_r")
      setModels(brandId.brand_id, cacheContext.cache?.models);
  }, [genericAutocompleteModel.open]);

  /**
   *
   */
  useEffect(() => {
    const localModel = Object.assign({}, modelCar);
    if (formState.defaultValues) {
      localModel.vin = formState.defaultValues?.vin;
      setDate({
        data_achizitie: formState.defaultValues?.data_achizitie,
        data_receptie: formState.defaultValues?.data_receptie,
      });
      setTVADeductibil(formState.defaultValues?.tva_id);
      setTVARecovered(formState.defaultValues?.TvaRecovered);
      const allData = [];
      formState.defaultValues &&
        formState.defaultValues?.dotari?.forEach((element) => {
          allData.push(element.id);
        });
      setDotari(formState.defaultValues?.dotari);

      // Vizible on Site
      if (!siteLink && formState.defaultValues?.slug) {
        setSiteLink(AUTODELRULATE_AD_LINK + formState.defaultValues?.slug);
      }
    }

    setModelCar(localModel);
    setBrandId({
      ...brandId,
      brand_id: formState.defaultValues
        ? formState.defaultValues?.brand_r?.id
        : 1,
    });
  }, [formState.defaultValues]);

  // /**
  //  * post car on site
  //  */
  // async function postCarOnSite() {
  //   try {
  //     // save car first if not saved
  //     const valid = await trigger();
  //     if (valid) {
  //       onSubmit(getValues()).then(async () => {
  //         try {
  //           const response = await postData(
  //             urlEnum.postOnSite + formState.defaultValues?.id,
  //             {}
  //           );
  //           if (!response || !response.data?.message?.slug) {
  //             setValue("slug", "");
  //             setValue("vizibil_site", "0"); ///????? NU VINE CAM NICIODATA 1
  //             return;
  //           }
  //           if (getValues("slug") == "" || getValues("slug") === null) {
  //             setSiteLink(AUTODELRULATE_AD_LINK + response.data?.message?.slug);
  //           }
  //         } catch (e) {
  //           setValue("slug", "");
  //           setValue("vizibil_site", "0");
  //         }
  //       });
  //     } else {
  //       toast.error(Vocabulary.fillInRequiredFields);
  //     }
  //   } catch (e) {
  //     toast.error(Vocabulary.errorOnSave);
  //   }
  // }

  /**
   *
   */
  function getDescriptionTemplates() {
    getData(`${urlEnum.genericTemplates}/type/${GenericTemplatesType[1]}`).then(
      (response) => {
        const templates = [];
        response?.data?.templates?.forEach((template) => {
          templates.push({
            id: template.id,
            templateName: template.templateName,
            html: template.html,
          });
        });
        setDescriptionTemplates(templates);
        if (isEmpty(vehicleContext.state.vehicle.descriere)) {
          if (vehicleContext.state.vehicle.id !== 0) {
            const template = templates.length > 1 ? templates[1] : templates[0];
            setSelectedTemplate(template);
            setValue(
              "descriere",
              templates.length > 0
                ? replaceMacro(
                    template.html,
                    vehicleContext.state.vehicle,
                    cacheContext.cache
                  )
                : ""
            );
            const vehicle = vehicleContext.state.vehicle;
            vehicle.descriere = getValues("descriere");
            vehicleContext.updateVehicle(vehicle);
          }
        }
      }
    );
  }
  /**
   *
   * @param {*} data
   */
  const onSubmit = (data, disableTostify = false) => {
    if (typeof disableTostify != "boolean") disableTostify = false;
    let newData = Object.assign({}, data);

    // // CHECK IF for VANZATOR_ID and DATA_VANZARE is set

    if (
      data.vanzator_id &&
      (data.data_vanzare === null || data.data_vanzare === undefined)
    ) {
      toast.error(Vocabulary.changeStatusError);
      return null;
    } else if (data.vanzator_id && data.data_vanzare) {
      newData.status_id = cacheContext.cache?.vehicleStatus?.find(
        (status) => status.nume === VehiclesStatus.sold
      ).id;
    }
    if (
      (data.vanzator_id === null || data.vanzator_id === undefined) &&
      data.data_vanzare
    ) {
      toast.error(Vocabulary.changeStatusError);
      return null;
    } else if (data.vanzator_id && data.data_vanzare) {
      newData.status_id = cacheContext.cache?.vehicleStatus?.find(
        (status) => status.nume === VehiclesStatus.sold
      ).id;
    }
    //VALIDATION TO NOT SEND EMPTY STRING TO BACKEND
    newData.pret_minim_vanzare =
      data.pret_minim_vanzare === "" ? 0 : data.pret_minim_vanzare;
    newData.numar_portiere =
      data.numar_portiere === "" ? 5 : data.numar_portiere;
    newData.nr_locuri = data.nr_locuri === "" ? 5 : data.numar_portiere;
    newData.kilometri = data.kilometri === "" ? 0 : data.kilometri;
    newData.an_fabricatie = data.an_fabricatie === "" ? 0 : data.an_fabricatie;

    console.log(data.vanzator_id);
    console.log(data.data_vanzare);

    //DATE
    newData.data_achizitie = data.data_achizitie
      ? moment(data.data_achizitie).format("YYYY-MM-DD HH:mm:ss")
      : null;
    newData.data_receptie = data.data_receptie
      ? moment(data.data_receptie).format("YYYY-MM-DD HH:mm:ss")
      : null;
    newData.data_vanzare = data.data_vanzare
      ? moment(data.data_vanzare).format("YYYY-MM-DD HH:mm:ss")
      : null;
    newData.data_inmatriculare = newData.data_inmatriculare
      ? moment(newData.data_inmatriculare).format("YYYY-MM-DD HH:mm:ss")
      : null;
    if (newData.obsTVA) {
      newData.obsTVA = data.obsTVA;
    } else {
      newData.obsTVA = null;
    }

    ///DOTARI

    newData.dotari = dotari;

    //STATUS
    if (!newData.status_id) {
      if (newData.tip_achizitie_id) {
        const tipAchizitie = cacheContext.cache?.purchaseType?.find((item) => {
          if (newData.tip_achizitie_id && newData.tip_achizitie_id[0]?.id)
            return item.id === newData.tip_achizitie_id[0]?.id;
          if (newData.tip_achizitie_id && !newData.tip_achizitie_id[0]?.id)
            return item.id === newData.tip_achizitie_id;
          return null;
        });
        if (tipAchizitie?.nume === PurchaseTypes.buyBack) {
          newData.status_id = cacheContext.cache?.vehicleStatus?.find(
            (status) => status.nume === VehiclesStatus.received
          ).id;
        } else {
          newData.status_id = cacheContext.cache?.vehicleStatus?.find(
            (status) => status.nume === VehiclesStatus.unpaid
          ).id;
        }
      } else {
        newData.status_id = cacheContext.cache?.vehicleStatus?.find(
          (status) => status.nume === VehiclesStatus.unpaid
        ).id;
      }
    }
    // CURS BNR
    if (!vehicleContext?.state?.vehicle?.id) {
      newData.cursEuro = bnrEuroExchangeRate;
    }

    // VISIBIL PE SITE
    return updateVehicle(newData, disableTostify);
  };

  const onError = (data) => {
    // eslint-disable-next-line no-console
    console.log(data); //E lasat aici pentru a vedeam campurile care nu sunt completate
  };

  /**
   *
   * @param {*} data
   */
  async function updateVehicle(data, disableTostify = false) {
    const vehicle = await vehicleContext.saveWithoutFile(
      () => true,
      null,
      null,
      data,
      disableTostify
    );

    vehicle.id && navigate(`/vehiclesDashboard/vehicleDetails/${vehicle.id}`);
  }

  /**
   *
   * @param {*} data
   * @param {*} title
   * @param {*} id
   */
  const handleClickEditDrop = (data, title, id) => {
    setGenericAutocompleteModel({
      ...genericAutocompleteModel,
      open: true,
      title: title,
      data: data,
      id: id,
      columns: getAutocompleteColumns(id),
      validationSchema: getAutocompleteValidationSchema(id),
      hiddenFieldsArray: ["brand_id", "id"],
      modelForFields: getAutocompleteModel(id),
    });
  };

  /**
   *
   * @param {*} id
   * @returns
   */
  const getAutocompleteValidationSchema = (id) => {
    switch (id) {
      case "locatie_administrativa_r":
        return AutocompleteSchemaForAddress;
      case "firma_achizitie_r":
        return AutocompleteSchemaForFirmaAchizitie;
      case "tip_tva_r":
        return AutocompleteSchemaForTipTVA;
      default:
        return AutocompleteSchema;
    }
  };

  /**
   *
   * @param {*} id
   * @returns
   */
  const getAutocompleteColumns = (id) => {
    switch (id) {
      case "locatie_administrativa_r":
        return columnsLocatieAdministrativa;
      case "firma_achizitie_r":
        return columnsFirmaAchizitie;
      case "tip_tva_r":
        return columnsTipTVA;
      default:
        return columns;
    }
  };

  /**
   *
   * @param {*} id
   * @returns
   */
  const getAutocompleteModel = (id) => {
    switch (id) {
      case "locatie_administrativa_r":
        return new AutocompleteModelForAddress();
      case "model_r":
        return brandId;

      case "firma_achizitie_r":
        return new AutocompleteModelForFirmaAchizitie();
      case "tip_tva_r":
        return new AutocompleteModelForTipTVA();
      default:
        return new AutocompleteModel();
    }
  };

  /**
   *
   */
  const columnsLocatieAdministrativa = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: true,
        sort: false,
        display: false,
      },
    },
    {
      name: "nume",
      label: "Oras",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "judet",
      label: "Judet",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "adresa",
      label: "Adresa",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "telefon",
      label: "Telefon",
      options: {
        filter: true,
        sort: false,
      },
    },
  ];

  /**
   *
   */
  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: true,
        sort: false,
        display: false,
      },
    },
    {
      name: "nume",
      label: "Nume",
      options: {
        filter: true,
        sort: false,
      },
    },
  ];

  /**
   *
   */
  const columnsFirmaAchizitie = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: true,
        sort: false,
        display: false,
      },
    },
    {
      name: "nume_firma",
      label: "Nume",
      options: {
        filter: true,
        sort: false,
      },
    },
  ];

  /**
   *
   */
  const columnsTipTVA = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: true,
        sort: false,
        display: false,
      },
    },
    {
      name: "nume",
      label: "Nume",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "valoare",
      label: "Valoare",
      options: {
        filter: true,
        sort: false,
      },
    },
  ];

  /**
   *
   * @param {*} data
   */
  const handleDotari = (data) => {
    setDotari(data);
  };

  /**
   *
   */
  function generateAd() {
    const token = localStorage.getItem("access_token");
    const options = {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        vehicleId: formState.defaultValues?.id,
        vehicle: vehicleContext.state.vehicle,
        siteLink: siteLink,
      }),
    };
    const fileName = `${moment().unix()}_Anunt_${
      formState.defaultValues?.vin
    }.docx`;
    fetch(`${urlEnum.templates}/${TemplatesType[1].name}`, options)
      .then((response) => response.blob())
      .then(async (response) => {
        const res = await getData(
          `${urlEnum.vehicles}/getDocuments/${formState.defaultValues?.id}`
        );
        vehicleContext.updateVehicleDocuments(res.data.documents);
        download(
          response,
          fileName,
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        );
      });
  }

  /**
   *
   * @param {*} param0
   * @returns
   */
  const DropDownButton = ({ children, ...other }) => (
    <Paper {...other}>
      <IconButton
        onMouseDown={(event) => {
          event.preventDefault();
          children[1] === "null" || children[1] === null
            ? handleClickEditDrop(
                children[2]?.props?.ownerState?.options,
                children[2]?.props?.ownerState?.label,
                children[2].props?.ownerState?.id
              )
            : handleClickEditDrop(
                children[1]?.props?.ownerState?.options,
                children[1]?.props?.ownerState?.label,
                children[1].props?.ownerState?.id
              );
        }}
        style={{
          position: "absolute",
          top: -40,
          right: isMobile() ? 0 : -36,
          margin: 5,
          display: "flex",
          justifyContent: "end",
          backgroundColor: "#FF6633",
          color: "#fff",
          borderRadius: 7,
          zIndex: 100,
        }}
        size="small"
        aria-label="editDropdown"
      >
        <ModeIcon fontSize="inherit" />
      </IconButton>
      {children}
    </Paper>
  );
  /**
   *
   * @param param0
   */
  function EditButton({ children, ...other }) {
    return (
      <Paper {...other}>
        <IconButton
          onMouseDown={(event) => {
            event.preventDefault();
            setOpenModalUser(true);
          }}
          style={{
            position: "absolute",
            top: -40,
            right: -36,
            margin: 5,
            display: "flex",
            justifyContent: "end",
            backgroundColor: "#FF6633",
            color: "#fff",
            borderRadius: 7,
            zIndex: 100,
          }}
          size="small"
          aria-label="editDropdown"
        >
          <AddCircleIcon fontSize="inherit" />
        </IconButton>
        {children}
      </Paper>
    );
  }

  /**
   *
   * @param param0
   */
  function EditButtonCompany({ children, ...other }) {
    return (
      <Paper {...other}>
        <IconButton
          onMouseDown={(event) => {
            event.preventDefault();
            setOpenModalCompany(true);
          }}
          style={{
            position: "absolute",
            top: -40,
            right: -36,
            margin: 5,
            display: "flex",
            justifyContent: "end",
            backgroundColor: "#FF6633",
            color: "#fff",
            borderRadius: 7,
            zIndex: 100,
          }}
          size="small"
          aria-label="editDropdown"
        >
          <AddCircleIcon fontSize="inherit" />
        </IconButton>
        {children}
      </Paper>
    );
  }

  /**
   *
   * @param {*} data
   * @param {*} cache
   * @returns
   */
  const setModels = (data, cache) => {
    let filteredModels = [];
    cache.forEach((value) => {
      if (value.brand_id === data) {
        filteredModels.push(value);
      }
    });
    setSortedModels(filteredModels);
    return filteredModels;
  };

  /**
   *
   * @param {*} data
   */
  const setBrand = (data) => {
    setBrandId({ ...brandId, brand_id: data.id });
    setSortedModels(setModels(data.id, cacheContext.cache?.models));
  };

  /**
   *
   * @param {*} data
   */
  const setTvaDeductibil = (data) => {
    setTVARecovered("0");
    setTVADeductibil(data.id);
  };

  /**
   * renders Vehicle Information
   * @returns
   */
  const renderVehicleInformation = () => (
    <fieldset
      style={{
        marginTop: 50,
        borderRadius: 10,
        margin: 10,
        borderColor: "#d6d6d6",
        borderStyle: "solid",
        color: "#444",
        width: isMobile() ? "auto" : "50%",
      }}
    >
      <legend>{Vocabulary.vehicleInformation}</legend>
      <Grid container columnSpacing={1}>
        <Grid item md={6} sm={12}>
          <Controller
            name="vin"
            control={control}
            defaultValue={formState.defaultValues?.vin}
            render={({
              field: { value, onChange, ref, ...field },
              fieldState: { error },
            }) => (
              <TextField
                {...field}
                disabled={props.editButton}
                className={style.textField}
                onChange={onChange}
                error={!!error}
                helperText={error?.message}
                value={value}
                id="vin"
                label="Serie Sasiu (VIN)"
                variant="outlined"
                size="small"
                inputRef={ref}
                inputProps={{
                  style: { textTransform: "uppercase" },
                }}
              />
            )}
          />

          <Controller
            name="tip_vehicul_r"
            control={control}
            defaultValue={cacheContext.cache?.vehicleTypes?.find(
              (element) =>
                element.id === formState.defaultValues?.tip_vehicul_id
            )}
            render={({ field: { ref, ...field }, fieldState: { error } }) => (
              <Autocomplete
                freeSolo={false}
                {...field}
                size="small"
                disableClearable
                disablePortal
                label="Tip Vehicul"
                disabled={props.editButton}
                PaperComponent={
                  props?.info?.editVehicle ? DropDownButton : null
                }
                isOptionEqualToValue={(option, value) =>
                  option?.nume === value?.nume
                }
                getOptionLabel={(option) => option?.nume}
                id="tip_vehicul_r"
                onChange={(event, value) => {
                  field.onChange(value);
                  setValue("tip_vehicul_id", value.id);
                }}
                options={cacheContext.cache?.vehicleTypes}
                renderInput={(params) => (
                  <TextField
                    required
                    className={style.textField}
                    error={!!error}
                    helperText={error?.message}
                    id="tip_vehicul_id"
                    label="Tip Vehicul"
                    name="tip_vehicul_id"
                    type="search"
                    inputRef={ref}
                    {...params}
                  />
                )}
              />
            )}
          />
          <Controller
            name="brand_r"
            control={control}
            defaultValue={formState.defaultValues?.brand_r}
            render={({ field: { ref, ...field }, fieldState: { error } }) => (
              <Autocomplete
                freeSolo={false}
                {...field}
                size="small"
                disableClearable
                disablePortal
                label="Brand"
                PaperComponent={
                  props?.info?.editVehicle ? DropDownButton : null
                }
                disabled={props.editButton}
                getOptionLabel={(option) => option?.nume}
                isOptionEqualToValue={(option, value) =>
                  option?.nume == value?.nume
                }
                id="brand_r"
                onChange={(event, value) => {
                  field.onChange(value);
                  setBrand(value);
                  setValue("brand_id", value.id);
                  setValue("model_r", null);
                }}
                options={cacheContext.cache?.brands}
                renderInput={(params) => (
                  <TextField
                    required
                    className={style.textField}
                    error={!!error}
                    helperText={error?.message}
                    id="brand_id"
                    label="Brand"
                    name="brand_id"
                    type="search"
                    inputRef={ref}
                    {...params}
                  />
                )}
              />
            )}
          />
          <Controller
            name="model_r"
            control={control}
            defaultValue={null}
            render={({ field: { ref, ...field }, fieldState: { error } }) => (
              <Autocomplete
                freeSolo={false}
                {...field}
                size="small"
                disableClearable
                disablePortal
                disabled={props.editButton}
                label="Model"
                PaperComponent={
                  props?.info?.editVehicle ? DropDownButton : null
                }
                getOptionLabel={(option) => option?.nume}
                id="model_r"
                onChange={(event, value) => {
                  field.onChange(value);
                  setValue("model_id", value.id);
                }}
                isOptionEqualToValue={(option, value) =>
                  option?.id == value?.id
                }
                renderOption={(props, option) => (
                  <li {...props} key={option.id}>  {/* Use unique key */}
                    {option.nume}
                  </li>
                )}
                options={sortedModels}
                renderInput={(params) => (
                  <TextField
                    required
                    className={style.textField}
                    error={!!error}
                    helperText={error?.message}
                    id="model_ids"
                    label="Model"
                    name="model_ids"
                    type="search"
                    inputRef={ref}
                    {...params}
                  />
                )}
              />
            )}
          />
          <Controller
            name="variant"
            defaultValue={formState.defaultValues?.variant}
            control={control}
            render={({ field: { value, onChange } }) => (
              <TextField
                className={style.textField}
                onChange={onChange}
                value={value}
                InputLabelProps={{
                  shrink: true,
                }}
                disabled={props.editButton}
                id="variant"
                label="Varianta"
                variant="outlined"
                size="small"
              />
            )}
          />
          <Controller
            name="tip_caroserie_r"
            control={control}
            defaultValue={cacheContext.cache?.bodyworkTypes?.find(
              (element) =>
                element.id === formState.defaultValues?.tip_caroserie_id
            )}
            render={({ field: { ref, ...field }, fieldState: { error } }) => (
              <Autocomplete
                freeSolo={false}
                {...field}
                size="small"
                disableClearable
                disablePortal
                label="Tip Caroserie"
                disabled={props.editButton}
                PaperComponent={
                  props?.info?.editVehicle ? DropDownButton : null
                }
                getOptionLabel={(option) => option?.nume}
                id="tip_caroserie_r"
                onChange={(event, value) => {
                  field.onChange(value);
                  setValue("tip_caroserie_id", value.id);
                }}
                options={cacheContext.cache?.bodyworkTypes}
                renderInput={(params) => (
                  <TextField
                    required
                    className={style.textField}
                    error={!!error}
                    helperText={error?.message}
                    id="tip_caroserie_id"
                    label="Tip Caroserie"
                    name="tip_caroserie_id"
                    type="search"
                    inputRef={ref}
                    {...params}
                  />
                )}
              />
            )}
          />
          <Controller
            name="an_fabricatie"
            defaultValue={formState.defaultValues?.an_fabricatie}
            control={control}
            render={({ field: { value, onChange } }) => (
              <TextField
                className={style.textField}
                onChange={onChange}
                value={value}
                disabled={props.editButton}
                id="an_fabricatie"
                label="An fabricatie"
                variant="outlined"
                size="small"
              />
            )}
          />
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <Controller
              name="data_inmatriculare"
              defaultValue={moment(formState.defaultValues?.data_inmatriculare)}
              control={control}
              render={({ field: { ...field } }) => (
                <DatePicker
                  {...field}
                  format={shortEuropeanDate}
                  className={style.textField}
                  id="data_inmatriculare"
                  value={
                    formState.defaultValues?.data_inmatriculare
                      ? moment(formState.defaultValues?.data_inmatriculare)
                      : null
                  }
                  disabled={props.editButton}
                  label="Data primei inmatriculari"
                  variant="outlined"
                  views={["month", "year"]}
                  slotProps={{ textField: { size: "small" } }}
                />
              )}
            />
          </LocalizationProvider>
          <Controller
            name="kilometri"
            control={control}
            defaultValue={formState.defaultValues?.kilometri}
            render={({ field: { value, onChange } }) => (
              <TextField
                className={style.textField}
                onChange={onChange}
                value={value}
                id="kilometri"
                InputProps={{
                  inputComponent: NumericFormatCustom,
                }}
                disabled={props.editButton}
                label="Kilometri"
                variant="outlined"
                size="small"
              />
            )}
          />

          <Controller
            name="tara_origine_r"
            control={control}
            defaultValue={cacheContext.cache?.countries?.find(
              (element) =>
                element.id === formState.defaultValues?.tara_origine_id
            )}
            render={({ field: { ref, ...field }, fieldState: { error } }) => (
              <Autocomplete
                freeSolo={false}
                {...field}
                size="small"
                disableClearable
                disablePortal
                PaperComponent={
                  props?.info?.editVehicle ? DropDownButton : null
                }
                disabled={props.editButton}
                getOptionLabel={(option) => option?.nume}
                id="tara_origine_r"
                label="Tara de origine"
                onChange={(event, value) => {
                  field.onChange(value);
                  setValue("tara_origine_id", value.id);
                }}
                options={cacheContext.cache?.countries}
                renderInput={(params) => (
                  <TextField
                    required
                    className={style.textField}
                    error={!!error}
                    helperText={error?.message}
                    id="tara_origine_id"
                    label="Tara de origine"
                    name="tara_origine_id"
                    type="search"
                    inputRef={ref}
                    {...params}
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item md={6} sm={12}>
          <Controller
            name="norma_poluare_r"
            control={control}
            defaultValue={cacheContext.cache?.pollutionNorms?.find(
              (element) =>
                element.id === formState.defaultValues?.norma_poluare_id
            )}
            render={({ field: { ref, ...field }, fieldState: { error } }) => (
              <Autocomplete
                freeSolo={false}
                {...field}
                size="small"
                disableClearable
                disablePortal
                disabled={props.editButton}
                label="Norma de poluare"
                PaperComponent={
                  props?.info?.editVehicle ? DropDownButton : null
                }
                getOptionLabel={(option) => option?.nume}
                id="norma_poluare_r"
                onChange={(event, value) => {
                  field.onChange(value);
                  setValue("norma_poluare_id", value.id);
                }}
                options={cacheContext.cache?.pollutionNorms}
                renderInput={(params) => (
                  <TextField
                    required
                    className={style.textField}
                    error={!!error}
                    helperText={error?.message}
                    id="norma_poluare_id"
                    label="Norma de poluare"
                    name="norma_poluare_id"
                    type="search"
                    inputRef={ref}
                    {...params}
                  />
                )}
              />
            )}
          />
          <Controller
            name="culoare_r"
            control={control}
            defaultValue={cacheContext.cache?.colors?.find(
              (element) => element.id === formState.defaultValues?.culoare_id
            )}
            render={({ field: { ref, ...field }, fieldState: { error } }) => (
              <Autocomplete
                freeSolo={false}
                {...field}
                size="small"
                disableClearable
                disablePortal
                PaperComponent={
                  props?.info?.editVehicle ? DropDownButton : null
                }
                disabled={props.editButton}
                label="Culoare"
                getOptionLabel={(option) => option?.nume}
                id="culoare_r"
                onChange={(event, value) => {
                  field.onChange(value);
                  setValue("culoare_id", value.id);
                }}
                options={cacheContext.cache?.colors}
                renderInput={(params) => (
                  <TextField
                    required
                    className={style.textField}
                    error={!!error}
                    helperText={error?.message}
                    id="culoare_id"
                    label="Culoare"
                    name="culoare_id"
                    type="search"
                    inputRef={ref}
                    {...params}
                  />
                )}
              />
            )}
          />

          <Controller
            name="optiune_culoare_r"
            control={control}
            defaultValue={cacheContext.cache?.colorsOptions?.find(
              (element) =>
                element.id === formState.defaultValues?.optiuni_culoare_id
            )}
            render={({ field: { ref, ...field }, fieldState: { error } }) => (
              <Autocomplete
                freeSolo={false}
                {...field}
                size="small"
                disableClearable
                disablePortal
                PaperComponent={
                  props?.info?.editVehicle ? DropDownButton : null
                }
                disabled={props.editButton}
                label="Optiuni Culoare"
                getOptionLabel={(option) => option?.nume}
                id="optiune_culoare_r"
                onChange={(event, value) => {
                  field.onChange(value);
                  setValue("optiuni_culoare_id", value.id);
                }}
                options={cacheContext.cache?.colorsOptions}
                renderInput={(params) => (
                  <TextField
                    required
                    className={style.textField}
                    error={!!error}
                    helperText={error?.message}
                    id="optiuni_culoare_id"
                    label="Optiuni Culoare"
                    name="optiuni_culoare_id"
                    type="search"
                    inputRef={ref}
                    {...params}
                  />
                )}
              />
            )}
          />
          <Controller
            name="tip_tractiune_r"
            control={control}
            defaultValue={cacheContext.cache?.tractionTypes?.find(
              (element) => element.id === formState.defaultValues?.tractiune_id
            )}
            render={({ field: { ref, ...field }, fieldState: { error } }) => (
              <Autocomplete
                freeSolo={false}
                {...field}
                size="small"
                disableClearable
                disablePortal
                disabled={props.editButton}
                label="Tractiune"
                PaperComponent={
                  props?.info?.editVehicle ? DropDownButton : null
                }
                getOptionLabel={(option) => option?.nume}
                id="tip_tractiune_r"
                onChange={(event, value) => {
                  field.onChange(value);
                  setValue("tractiune_id", value.id);
                }}
                options={cacheContext.cache?.tractionTypes}
                renderInput={(params) => (
                  <TextField
                    required
                    className={style.textField}
                    error={!!error}
                    helperText={error?.message}
                    id="tractiune_id"
                    label="Tractiune"
                    name="tractiune_id"
                    type="search"
                    inputRef={ref}
                    {...params}
                  />
                )}
              />
            )}
          />

          <Controller
            name="combustibil_r"
            control={control}
            defaultValue={cacheContext.cache?.fuelTypes?.find(
              (element) =>
                element.id === formState.defaultValues?.combustibil_id
            )}
            render={({ field: { ref, ...field }, fieldState: { error } }) => (
              <Autocomplete
                freeSolo={false}
                {...field}
                size="small"
                disableClearable
                disablePortal
                disabled={props.editButton}
                label="Tip Combustibil"
                PaperComponent={
                  props?.info?.editVehicle ? DropDownButton : null
                }
                getOptionLabel={(option) => option?.nume}
                id="combustibil_r"
                onChange={(event, value) => {
                  field.onChange(value);
                  setValue("combustibil_id", value.id);
                }}
                options={cacheContext.cache?.fuelTypes}
                renderInput={(params) => (
                  <TextField
                    required
                    className={style.textField}
                    error={!!error}
                    helperText={error?.message}
                    id="combustibil_id"
                    label="Tip Combustibil"
                    name="combustibil_id"
                    type="search"
                    inputRef={ref}
                    {...params}
                  />
                )}
              />
            )}
          />

          <Controller
            name="cutie_viteze_r"
            control={control}
            defaultValue={cacheContext.cache?.gearboxes?.find(
              (element) =>
                element.id === formState.defaultValues?.cutie_viteze_id
            )}
            render={({ field: { ref, ...field }, fieldState: { error } }) => (
              <Autocomplete
                freeSolo={false}
                {...field}
                size="small"
                disableClearable
                disablePortal
                disabled={props.editButton}
                label="Cutie de viteze"
                PaperComponent={
                  props?.info?.editVehicle ? DropDownButton : null
                }
                getOptionLabel={(option) => option?.nume}
                id="cutie_viteze_r"
                onChange={(event, value) => {
                  field.onChange(value);
                  setValue("cutie_viteze_id", value.id);
                }}
                options={cacheContext.cache?.gearboxes}
                renderInput={(params) => (
                  <TextField
                    required
                    className={style.textField}
                    error={!!error}
                    helperText={error?.message}
                    id="cutie_viteze_id"
                    label="Cutie de viteze"
                    name="cutie_viteze_id"
                    type="search"
                    inputRef={ref}
                    {...params}
                  />
                )}
              />
            )}
          />
          <Controller
            name="capacitate_cilindrica"
            defaultValue={formState.defaultValues?.capacitate_cilindrica}
            control={control}
            render={({ field: { value, onChange } }) => (
              <TextField
                className={style.textField}
                onChange={onChange}
                value={value}
                id="capacitate_cilindrica"
                label="Capacitate cilindrica"
                variant="outlined"
                disabled={props.editButton}
                size="small"
              />
            )}
          />
          <Controller
            name="putere_cp"
            defaultValue={formState.defaultValues?.putere_cp}
            control={control}
            render={({ field: { value, onChange } }) => (
              <TextField
                className={style.textField}
                onChange={onChange}
                value={value}
                id="putere_cp"
                label="Putere CP"
                disabled={props.editButton}
                variant="outlined"
                size="small"
              />
            )}
          />
          <Controller
            name="nr_locuri"
            defaultValue={formState.defaultValues?.nr_locuri}
            control={control}
            render={({ field: { value, onChange } }) => (
              <TextField
                className={style.textField}
                onChange={onChange}
                value={value}
                id="nr_locuri"
                disabled={props.editButton}
                label="Nr. Locuri"
                variant="outlined"
                size="small"
              />
            )}
          />

          <Controller
            name="numar_portiere"
            control={control}
            defaultValue={formState.defaultValues?.numar_portiere}
            render={({ field: { value, onChange } }) => (
              <TextField
                className={style.textField}
                onChange={onChange}
                value={value}
                id="numar_portiere"
                label="Nr. Portiere"
                disabled={props.editButton}
                variant="outlined"
                size="small"
              />
            )}
          />
        </Grid>
      </Grid>
    </fieldset>
  );

  /**
   * render Administrative Information
   * @returns
   */
  const renderAdministrativeInfo = () => (
    <fieldset
      style={{
        marginTop: 50,
        borderRadius: 10,
        margin: 10,
        borderColor: "#d6d6d6",
        borderStyle: "solid",
        color: "#444",
        width: isMobile() ? "auto" : "50%",
      }}
    >
      <legend>{Vocabulary.administrativeInformation}</legend>
      <Grid container rowSpacing={1} columnSpacing={1}>
        <Grid item md={6} sm={12} xs={12}>
          <Controller
            name="user_achizitie_r"
            control={control}
            defaultValue={formState.defaultValues?.user_achizitie_id}
            render={({ field: { ref, ...field }, fieldState: { error } }) => (
              <Autocomplete
                freeSolo={false}
                {...field}
                size="small"
                disablePortal
                disableClearable
                disabled={props.editButton}
                label="User Achizitie"
                PaperComponent={EditButton}
                getOptionLabel={(cur) => {
                  return cur.nume || cur.name;
                }}
                id="user_achizitie_r"
                onChange={(event, value) => {
                  field.onChange(value);
                  setValue("user_achizitie_id", value.id);
                }}
                options={cacheContext.cache?.users}
                renderInput={(params) => (
                  <TextField
                    required
                    className={style.textField}
                    error={!!error}
                    helperText={error?.message}
                    id="user_achizitie_id"
                    label="User Achizitie"
                    name="user_achizitie_id"
                    type="search"
                    inputRef={ref}
                    {...params}
                  />
                )}
              />
            )}
          />
          <Controller
            name="user_logistica_r"
            control={control}
            defaultValue={cacheContext.cache?.users?.find(
              (element) =>
                element.id === formState.defaultValues?.user_logistica_id
            )}
            render={({ field: { ref, ...field }, fieldState: { error } }) => (
              <Autocomplete
                freeSolo={false}
                {...field}
                size="small"
                disableClearable
                disablePortal
                disabled={props.editButton}
                label="User Logistica"
                PaperComponent={EditButton}
                getOptionLabel={(cur) => {
                  return cur.nume || cur.name;
                }}
                id="user_logistica_r"
                onChange={(event, value) => {
                  field.onChange(value);
                  setValue("user_logistica_id", value.id);
                }}
                options={cacheContext.cache?.users}
                renderInput={(params) => (
                  <TextField
                    required
                    className={style.textField}
                    error={!!error}
                    helperText={error?.message}
                    id="user_logistica_id"
                    label="User Logistica"
                    name="user_logistica_id"
                    type="search"
                    inputRef={ref}
                    {...params}
                  />
                )}
              />
            )}
          />
          {isIntermediere?.nume == "Intermediere" && (
            <Controller
              name="user_intermediere"
              control={control}
              defaultValue={cacheContext.cache?.users?.find(
                (element) =>
                  element.id === formState.defaultValues?.user_intermediere
              )}
              render={({ field: { ref, ...field }, fieldState: { error } }) => (
                <Autocomplete
                  freeSolo={false}
                  {...field}
                  size="small"
                  disablePortal
                  disableClearable
                  disabled={props.editButton}
                  label={Vocabulary.userIntermediere}
                  PaperComponent={EditButton}
                  getOptionLabel={(cur) => {
                    return cur?.nume || cur?.name;
                  }}
                  id="user_intermediere"
                  onChange={(event, value) => {
                    field.onChange(value);
                    setValue("user_intermediere_id", value.id);
                  }}
                  options={cacheContext.cache?.users}
                  renderInput={(params) => (
                    <TextField
                      required
                      className={style.textField}
                      error={!!error}
                      helperText={error?.message}
                      id="user_intermediere_id"
                      label={Vocabulary.userIntermediere}
                      name="user_intermediere_id"
                      type="search"
                      inputRef={ref}
                      {...params}
                    />
                  )}
                />
              )}
            />
          )}

          <Controller
            name="vanzator"
            control={control}
            defaultValue={[
              cacheContext.cache?.users?.find(
                (element) => element.id === formState.defaultValues?.vanzator_id
              ),
            ]}
            render={({ field: { ref, ...field }, fieldState: { error } }) => (
              <Autocomplete
                freeSolo={false}
                {...field}
                size="small"
                disablePortal
                disableClearable
                disabled={props.editButton || !formState.defaultValues?.id}
                label="Vanzator"
                PaperComponent={EditButton}
                getOptionLabel={(option) => option?.nume || option?.name || ""}
                id="vanzator"
                onChange={(event, value) => {
                  field.onChange(value);
                  setValue("vanzator_id", value ? value?.id : null);
                }}
                options={cacheContext?.cache?.users}
                renderInput={(params) => (
                  <TextField
                    className={style.textField}
                    error={!!error}
                    helperText={error?.message}
                    id="vanzator_id"
                    label="Vanzator"
                    name="vanzator_id"
                    type="search"
                    inputRef={ref}
                    {...params}
                  />
                )}
              />
            )}
          />

          <Controller
            name="tip_achizitie_r"
            control={control}
            defaultValue={cacheContext.cache?.purchaseType?.find(
              (element) =>
                element.id === formState.defaultValues?.tip_achizitie_id
            )}
            render={({ field: { ref, ...field }, fieldState: { error } }) => (
              <Autocomplete
                freeSolo={false}
                {...field}
                size="small"
                disableClearable
                disablePortal
                disabled={
                  props.editButton || formState.defaultValues?.tip_achizitie_id
                }
                label="Tip achizitie"
                PaperComponent={
                  props?.info?.editVehicle ? DropDownButton : null
                }
                getOptionLabel={(option) => option?.nume}
                id="tip_achizitie_r"
                onChange={(event, value) => {
                  field.onChange(value);
                  setValue("tip_achizitie_id", value.id);
                }}
                options={cacheContext.cache?.purchaseType}
                renderInput={(params) => (
                  <TextField
                    required
                    className={style.textField}
                    error={!!error}
                    helperText={error?.message}
                    id="tip_achizitie_id"
                    label="Tip achizitie"
                    name="tip_achizitie_id"
                    type="search"
                    inputRef={ref}
                    {...params}
                  />
                )}
              />
            )}
          />
          <Controller
            name="firma_achizitie_r"
            control={control}
            defaultValue={cacheContext.cache?.company?.find(
              (element) =>
                element.id === formState.defaultValues?.firma_achizitie_id
            )}
            render={({ field: { ref, ...field }, fieldState: { error } }) => (
              <Autocomplete
                freeSolo={false}
                {...field}
                size="small"
                disableClearable
                disablePortal
                disabled={props.editButton}
                label="Firma Achizitie"
                PaperComponent={EditButtonCompany}
                getOptionLabel={(option) => option.nume_firma}
                id="firma_achizitie_r"
                onChange={(event, value) => {
                  field.onChange(value);
                  setValue("firma_achizitie_id", value.id);
                }}
                options={cacheContext.cache?.company}
                renderInput={(params) => (
                  <TextField
                    required
                    className={style.textField}
                    error={!!error}
                    helperText={error?.message}
                    id="firma_achizitie_id"
                    label="Firma Achizitie"
                    name="firma_achizitie"
                    type="search"
                    inputRef={ref}
                    {...params}
                  />
                )}
              />
            )}
          />
          <Controller
            name="furnizor_r"
            control={control}
            defaultValue={cacheContext.cache?.providers?.find(
              (element) => element.id === formState.defaultValues?.furnizor_id
            )}
            render={({ field: { ref, ...field }, fieldState: { error } }) => (
              <Autocomplete
                freeSolo={false}
                {...field}
                size="small"
                disableClearable
                disablePortal
                PaperComponent={props?.info?.editAdmin ? DropDownButton : null}
                disabled={props.editButton}
                label={Vocabulary.supplier}
                getOptionLabel={(option) => option?.nume}
                id="furnizor_r"
                onChange={(event, value) => {
                  field.onChange(value);
                  setValue("furnizor_id", value.id);
                }}
                options={cacheContext.cache?.providers}
                renderInput={(params) => (
                  <TextField
                    required
                    className={style.textField}
                    error={!!error}
                    helperText={error?.message}
                    id="furnizor_id"
                    label={Vocabulary.supplier}
                    name="furnizor_id"
                    type="search"
                    inputRef={ref}
                    {...params}
                  />
                )}
              />
            )}
          />
          <Controller
            name="locatie_administrativa_r"
            control={control}
            defaultValue={formState.defaultValues?.locatie_administrativa_r}
            render={({ field: { ref, ...field }, fieldState: { error } }) => (
              <Autocomplete
                freeSolo={false}
                {...field}
                size="small"
                disableClearable
                disablePortal
                PaperComponent={props?.info?.editAdmin ? DropDownButton : null}
                disabled={props.editButton}
                label={Vocabulary.location}
                getOptionLabel={(option) => option.nume}
                id="locatie_administrativa_r"
                onChange={(event, value) => {
                  field.onChange(value);
                  setValue("locatie_administrativa_id", value.id);
                }}
                options={cacheContext.cache?.locations}
                renderInput={(params) => (
                  <TextField
                    required
                    className={style.textField}
                    error={!!error}
                    helperText={error?.message}
                    id="locatie_administrativa_id"
                    label={Vocabulary.location}
                    name="locatie_administrativa_id"
                    type="search"
                    inputRef={ref}
                    {...params}
                  />
                )}
              />
            )}
          />
          <Controller
            name="tip_tva_r"
            control={control}
            defaultValue={cacheContext.cache?.tvaType?.find(
              (element) => element.id === formState.defaultValues?.tva_id
            )}
            render={({ field: { ref, ...field }, fieldState: { error } }) => (
              <Autocomplete
                freeSolo={false}
                {...field}
                size="small"
                disableClearable
                disablePortal
                disabled={props.editButton}
                label={Vocabulary.tipTVA}
                PaperComponent={
                  props?.info?.editVehicle ? DropDownButton : null
                }
                getOptionDisabled={(option) =>
                  TVARecovered === 1
                    ? option === cacheContext.cache?.tvaType[1]
                    : null
                }
                getOptionLabel={(option) => option?.nume}
                id="tip_tva_r"
                onChange={(event, value) => {
                  field.onChange(value);
                  setTvaDeductibil(value);
                  setValue("tva_id", value.id);
                }}
                options={cacheContext.cache?.tvaType}
                renderInput={(params) => (
                  <Tooltip
                    title={
                      TVARecovered === 1 ? Vocabulary.editTVADeductibil : ""
                    }
                  >
                    <TextField
                      required
                      className={style.textField}
                      error={!!error}
                      helperText={error?.message}
                      id="tva_id"
                      label={Vocabulary.tipTVA}
                      name="tva_id"
                      type="search"
                      inputRef={ref}
                      {...params}
                    />
                  </Tooltip>
                )}
              />
            )}
          />
          <Controller
            name="bonus_vanzare_vehicul"
            control={control}
            render={({ field: { onChange } }) => (
              <TextField
                className={style.textField}
                disabled={
                  props.editButton ? props.editButton : !props.sellBonus
                } //show only to sellBonus permission
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">Lei</InputAdornment>
                  ),
                }}
                inputProps={{ readOnly: props.editButton }}
                onChange={onChange}
                xs
                value={formState.defaultValues?.bonus_vanzare_vehicul}
                id="bonus_vanzare_vehicul"
                label={Vocabulary.vehicleSellingBonus}
                variant="outlined"
                size="small"
              />
            )}
          />
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <Controller
            name="cursEuro_r"
            control={control}
            defaultValue={
              formState.defaultValues?.id
                ? formState.defaultValues?.cursEuro
                : bnrEuroExchangeRate
            }
            render={({ field: { onChange } }) => (
              <TextField
                className={style.textField}
                disabled
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">Lei</InputAdornment>
                  ),
                }}
                inputProps={{ readOnly: props.editButton }}
                onChange={onChange}
                xs
                value={
                  formState.defaultValues?.id
                    ? formState.defaultValues?.cursEuro
                    : bnrEuroExchangeRate
                }
                id="cursEuro"
                label="Curs Euro"
                variant="outlined"
                size="small"
              />
            )}
          />

          <Grid style={{ display: "flex", width: "100%" }} container xl={12}>
            <Grid sx={{ paddingRight: 0.5 }} xl={6} xs={12}>
              <Controller
                name="pret_achizitie_euro"
                control={control}
                defaultValue={formState.defaultValues?.pret_achizitie_euro}
                render={({ field: { value, onChange } }) => (
                  <TextField
                    className={style.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">€</InputAdornment>
                      ),
                    }}
                    disabled
                    inputProps={{ readOnly: true }}
                    onChange={onChange}
                    value={value}
                    id="pret_achizitie_euro"
                    type="number"
                    label={Vocabulary.purchasePriceEuro}
                    variant="outlined"
                    size="small"
                  />
                )}
              />
            </Grid>
            <Grid item xl={6} xs={12}>
              <Controller
                name="pret_achizitie_ron"
                control={control}
                defaultValue={formState.defaultValues?.pret_achizitie_ron}
                render={({ field: { value, onChange } }) => (
                  <TextField
                    className={style.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">Lei</InputAdornment>
                      ),
                    }}
                    disabled
                    inputProps={{ readOnly: true }}
                    onChange={onChange}
                    value={value}
                    id="pret_achizitie_ron"
                    type="number"
                    label={Vocabulary.purchasePriceLei}
                    variant="outlined"
                    size="small"
                  />
                )}
              />
            </Grid>
          </Grid>
          {formState.defaultValues?.id ? (
            <Grid style={{ display: "flex", width: "100%" }} container xl={12}>
              <Grid sx={{ paddingRight: 0.5 }} xl={6} xs={12}>
                <Controller
                  name="pret_vanzare_euro"
                  control={control}
                  defaultValue={formState.defaultValues?.pret_vanzare_euro}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      className={style.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      disabled={props.editButton}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">€</InputAdornment>
                        ),
                      }}
                      onChange={(value) => {
                        onChange(value);
                        setValue("pret_vanzare_euro", +value.target.value);
                      }}
                      onBlur={(event) => {
                        if (!getValues("pret_vanzare_ron"))
                          setValue(
                            "pret_vanzare_ron",
                            (
                              +event.target.value *
                              (formState.defaultValues?.id
                                ? formState.defaultValues?.cursEuro
                                : bnrEuroExchangeRate)
                            ).toFixed(2)
                          );
                      }}
                      value={value}
                      id="pret_vanzare_euro"
                      type="number"
                      label={Vocabulary.salePriceEuro}
                      variant="outlined"
                      size="small"
                    />
                  )}
                />
              </Grid>
              <Grid item xl={6} xs={12}>
                <Controller
                  name="pret_vanzare_ron"
                  control={control}
                  defaultValue={formState.defaultValues?.pret_vanzare_ron}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      className={style.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      disabled={props.editButton}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">Lei</InputAdornment>
                        ),
                      }}
                      onChange={(value) => {
                        onChange(value);
                        setValue("pret_vanzare_ron", +value.target.value);
                      }}
                      onBlur={(event) => {
                        if (!getValues("pret_vanzare_euro"))
                          setValue(
                            "pret_vanzare_euro",
                            (
                              +event.target.value /
                              (formState.defaultValues?.id
                                ? formState.defaultValues?.cursEuro
                                : bnrEuroExchangeRate)
                            ).toFixed(2)
                          );
                      }}
                      value={value}
                      id="pret_vanzare_ron"
                      type="number"
                      label={Vocabulary.salePriceRon}
                      variant="outlined"
                      size="small"
                    />
                  )}
                />
              </Grid>
            </Grid>
          ) : null}
          <Controller
            name="pret_site_vanzare"
            control={control}
            defaultValue={formState.defaultValues?.pret_site_vanzare}
            render={({ field: { value, onChange } }) => (
              <TextField
                className={style.textField}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">€</InputAdornment>
                  ),
                }}
                disabled={props.editButton}
                onChange={onChange}
                value={value}
                id="pret_site_vanzare"
                label={Vocabulary.priceOnWebsite}
                variant="outlined"
                size="small"
              />
            )}
          />
          <Controller
            name="pret_minim_vanzare"
            defaultValue={formState.defaultValues?.pret_minim_vanzare}
            control={control}
            render={({ field: { value, onChange } }) => (
              <TextField
                className={style.textField}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">€</InputAdornment>
                  ),
                }}
                disabled={props.editButton}
                onChange={onChange}
                value={value}
                id="pret_minim_vanzare"
                type="number"
                label={Vocabulary.minimumSalePrice}
                variant="outlined"
                size="small"
              />
            )}
          />
          {formState.defaultValues?.id ? (
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <Controller
                name="data_achizitie"
                defaultValue={moment(formState.defaultValues?.data_achizitie)}
                control={control}
                render={({
                  field: { ...field },
                  fieldState: { error, invalid },
                }) => (
                  <>
                    <DatePicker
                      {...field}
                      format={europeanDate}
                      className={style.textField}
                      disabled={props.editButton}
                      id="data_achizitie"
                      value={
                        formState.defaultValues?.data_achizitie
                          ? moment(formState.defaultValues?.data_achizitie)
                          : null
                      }
                      onChange={(value) => {
                        field.onChange(value);
                        setDate({ ...date, data_achizitie: value });
                      }}
                      label={Vocabulary.purchaseDate}
                      variant="outlined"
                      slotProps={{ textField: { size: "small" } }}
                      renderInput={(params) => (
                        <TextField
                          id="data_achizitie"
                          variant="standard"
                          margin="dense"
                          fullWidth
                          color="primary"
                          autoComplete="bday"
                          {...params}
                        />
                      )}
                    />
                    <Typography
                      style={{
                        fontSize: 11,
                        color: "#f33",
                        marginTop: 3,
                        marginLeft: 5,
                      }}
                    >
                      {error?.message}
                    </Typography>
                  </>
                )}
              />

              <Controller
                name="data_receptie"
                control={control}
                defaultValue={moment(formState.defaultValues?.data_receptie)}
                render={({
                  field: { ...field },
                  fieldState: { error, invalid },
                }) => (
                  <>
                    <DatePicker
                      className={style.textField}
                      format={europeanDate}
                      style={{ height: 50 }}
                      {...field}
                      id="data_receptie"
                      disabled={props.editButton}
                      minDate={
                        date.data_achizitie ? moment(date.data_achizitie) : null
                      }
                      value={
                        formState.defaultValues?.data_receptie
                          ? moment(formState.defaultValues?.data_receptie)
                          : null
                      }
                      onChange={(value) => {
                        field.onChange(value);
                        setDate({
                          ...date,
                          data_receptie: value,
                        });
                      }}
                      label={Vocabulary.receptionDate}
                      variant="outlined"
                      slotProps={{ textField: { size: "small" } }}
                      renderInput={(params) => (
                        <TextField
                          id="data_achizitie"
                          variant="standard"
                          margin="dense"
                          fullWidth
                          color="primary"
                          autoComplete="bday"
                          {...params}
                        />
                      )}
                    />
                    <Typography
                      style={{
                        fontSize: 11,
                        color: "#f33",
                        marginTop: 3,
                        marginLeft: 5,
                      }}
                    >
                      {error?.message}
                    </Typography>
                  </>
                )}
              />
              <Controller
                name="data_vanzare"
                control={control}
                defaultValue={moment(formState.defaultValues?.data_vanzare)}
                render={({
                  field: { ...field },
                  fieldState: { error, invalid },
                }) => (
                  <>
                    <DatePicker
                      className={style.textField}
                      format={europeanDate}
                      style={{ height: 50 }}
                      {...field}
                      id="data_vanzare"
                      maxDate={moment()}
                      disabled={props.editButton}
                      value={
                        formState.defaultValues?.data_vanzare
                          ? moment(formState.defaultValues?.data_vanzare)
                          : null
                      }
                      onChange={(value) => {
                        field.onChange(value);
                        setDate({
                          ...date,

                          data_vanzare: value,
                        });
                      }}
                      label={Vocabulary.sellDate}
                      variant="outlined"
                      slotProps={{ textField: { size: "small" } }}
                      renderInput={(params) => (
                        <TextField
                          id="data_vanzare"
                          variant="standard"
                          margin="dense"
                          fullWidth
                          color="primary"
                          autoComplete="bday"
                          {...params}
                        />
                      )}
                    />
                    <Typography
                      style={{
                        fontSize: 11,
                        color: "#f33",
                        marginTop: 3,
                        marginLeft: 5,
                      }}
                    >
                      {error?.message}
                    </Typography>
                  </>
                )}
              />
            </LocalizationProvider>
          ) : null}
          {TVADeductibil === 1 ? (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: 12,
                marginTop: 15,
              }}
            >
              <Typography style={{ margin: 8, marginLeft: 17 }}>
                {Vocabulary.tvaRecover}
              </Typography>
              <Controller
                defaultValue={TVARecovered}
                name="TvaRecovered"
                control={control}
                render={({ field: { value, onChange, ref } }) => (
                  <Switch
                    defaultChecked={
                      TVARecovered.toString() === "1" ? true : false
                    }
                    disabled={props.editButton}
                    onChange={(e) => {
                      onChange(e.target.checked ? "1" : "0");
                      setTVARecovered(e.target.checked ? 1 : 0);
                    }}
                    value={value}
                    id="tva_id"
                    inputRef={ref}
                  />
                )}
              />
            </div>
          ) : null}
          {TVARecovered === 1 ? (
            <Controller
              name="obsTVA"
              defaultValue={formState.defaultValues?.obsTVA}
              control={control}
              render={({ field: { value, onChange } }) => (
                <TextField
                  style={{ width: "100%" }}
                  onChange={onChange}
                  value={value}
                  disabled={props.editButton}
                  id="obsTVA"
                  label={`${Vocabulary.observations} - ${Vocabulary.tvaRecover}`}
                  size="small"
                  variant="outlined"
                  multiline
                  rows={3}
                />
              )}
            />
          ) : null}
        </Grid>

        <Grid item={1} xl={12} md={12} xs={12}>
          <div>
            {formState.defaultValues?.id ? (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: 12,
                    border:
                      dotari && dotari.length > 0
                        ? "0.5px solid #fff"
                        : "0.5px solid #f33",
                    borderRadius: 5,
                    margin: dotari && dotari.length > 0 ? 0 : -3,
                  }}
                >
                  <Typography style={{ margin: 10, marginLeft: 17 }}>
                    {Vocabulary.visibleOnSite}
                    {siteLink && (
                      <>
                        <br />
                        <a href={siteLink}>
                          <small>{siteLink}</small>
                        </a>
                      </>
                    )}
                  </Typography>
                  <Tooltip title={Vocabulary.visibleOnSiteMessage}>
                    <Controller
                      defaultValue={
                        formState.defaultValues?.vizibil_site &&
                        !!parseInt(
                          formState.defaultValues?.vizibil_site.toString()
                        )
                      }
                      name="vizibil_site"
                      control={control}
                      render={({ field: { value, onChange, ref } }) => (
                        <>
                          <Switch
                            checked={
                              getValues("vizibil_site") == "1" ? true : false
                            }
                            onChange={(e) => {
                              onChange(e.target.checked ? "1" : "0");
                            }}
                            readOnly={getValues("vizibil_site") == "1"}
                            disabled={
                              props.editButton &&
                              formState.defaultValues.dotari &&
                              !formState.defaultValues.dotari.length
                            }
                            value={value}
                            id="vizibil_site"
                            inputRef={ref}
                          />
                        </>
                      )}
                    />
                  </Tooltip>
                  <Button
                    variant="contained"
                    size="small"
                    style={{ color: "white", margin: 10 }}
                    disabled={props.editButton}
                    onClick={(e) => {
                      onSubmit(getValues(), true).then(async () => {
                        setOpenIntegrationModal(true);
                      });
                    }}
                  >
                    {Vocabulary.publishOnSite}
                  </Button>

                  <Button
                    variant="outlined"
                    size="small"
                    style={{ margin: 10 }}
                    disabled={props.editButton}
                    onClick={generateAd}
                  >
                    {Vocabulary.genereateAd}
                  </Button>
                </div>
                {dotari && dotari.length > 0 ? null : (
                  <Typography
                    style={{
                      fontSize: 11,
                      color: "#f33",
                      marginTop: 3,
                      marginLeft: 5,
                    }}
                  >
                    {Vocabulary.visibleOnSiteMessage}
                  </Typography>
                )}
              </>
            ) : null}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: dotari && dotari.length > 0 ? 5 : -3,
              }}
            >
              <Typography style={{ margin: 8, marginLeft: 17 }}>
                {Vocabulary.historicVehicle}
              </Typography>
              <Controller
                defaultValue={formState.defaultValues?.retromobil}
                name="retromobil"
                control={control}
                render={({ field: { value, onChange, ref } }) => (
                  <Switch
                    defaultChecked={
                      formState.defaultValues?.retromobil === "1" ? true : false
                    }
                    disabled={props.editButton}
                    onChange={(e) => {
                      onChange(e.target.checked ? "1" : "0");
                    }}
                    value={value}
                    name="retromobil"
                    id="retromobil"
                    inputRef={ref}
                  />
                )}
              />
            </div>
            <Divider orientation="vertical" variant="middle" flexItem />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: 5,
              }}
            >
              <Typography style={{ margin: 8, marginLeft: 17 }}>
                {Vocabulary.serviceBook}
              </Typography>
              <Controller
                defaultValue={formState.defaultValues?.carte_service}
                name="carte_service"
                control={control}
                render={({ field: { value, onChange, ref } }) => (
                  <Switch
                    defaultChecked={
                      formState.defaultValues?.carte_service === "1"
                        ? true
                        : false
                    }
                    disabled={props.editButton}
                    id="carte_service"
                    onChange={(e) => {
                      onChange(e.target.checked ? "1" : "0");
                    }}
                    value={value}
                    inputRef={ref}
                  />
                )}
              />
            </div>
            <Divider orientation="vertical" variant="middle" flexItem />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: 5,
              }}
            >
              <Typography style={{ margin: 8, marginLeft: 17 }}>
                {Vocabulary.tuning}
              </Typography>
              <Controller
                defaultValue={formState.defaultValues?.tuning}
                name="tuning"
                control={control}
                render={({ field: { value, onChange, ref } }) => (
                  <Switch
                    defaultChecked={
                      formState.defaultValues?.tuning === "1" ? true : false
                    }
                    disabled={props.editButton}
                    id="tuning"
                    onChange={(e) => {
                      onChange(e.target.checked ? "1" : "0");
                    }}
                    value={value}
                    inputRef={ref}
                  />
                )}
              />
            </div>
            <Divider orientation="vertical" variant="middle" flexItem />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: 5,
              }}
            >
              <Typography style={{ margin: 8, marginLeft: 17 }}>
                {Vocabulary.rightSideSteeringWheel}
              </Typography>
              <Controller
                defaultValue={formState.defaultValues?.volan}
                name="volan"
                control={control}
                render={({ field: { value, onChange, ref } }) => (
                  <Switch
                    defaultChecked={
                      formState.defaultValues?.volan === "1" ? true : false
                    }
                    disabled={props.editButton}
                    id="volan"
                    onChange={(e) => {
                      onChange(e.target.checked ? "1" : "0");
                    }}
                    value={value}
                    inputRef={ref}
                  />
                )}
              />
            </div>
          </div>
        </Grid>
      </Grid>
    </fieldset>
  );

  /**
   * render Equipment (Dotari)
   * @returns
   */
  const renderEquipment = () =>
    // <fieldset
    //   style={{
    //     marginTop: 50,
    //     borderRadius: 10,
    //     margin: 10,
    //     borderColor: "#d6d6d6",
    //     borderStyle: "solid",
    //     color: "#444",
    //   }}
    // >
    //   <legend>{Vocabulary.equipment}</legend>
    {
      return (
        <Controller
          name="dotari"
          control={control}
          render={({ field: { value, onChange, ...field } }) => (
            <Autocomplete
              {...field}
              id="dotari"
              multiple
              open={open}
              defaultValue={formState?.defaultValues?.dotari}
              onOpen={() => setOpen(true)}
              onClose={handleClose}
              getOptionLabel={(option) => option?.nume}
              onChange={(e, item) => {
                handleDotari(item);
              }}
              disabled={props.editButton}
              options={cacheContext.cache?.facilities}
              required
              ListboxProps={{
                sx: {
                  display: "grid !important",
                  gridTemplateColumns: "1fr 1fr 1fr",
                },
              }}
              isOptionEqualToValue={(option, value) =>
                option?.nume == value?.nume
              }
              sx={{
                margin: "10px",
              }}
              renderInput={(params) => (
                <TextField label="Dotari" type="search" {...params} />
              )}
            />
          )}
        />
      );
    };
  // </fieldset>

  function showDescription() {
    return (
      <fieldset
        style={{
          marginTop: 50,
          borderRadius: 10,
          margin: 10,
          borderColor: "#d6d6d6",
          borderStyle: "solid",
          color: "#444",
        }}
      >
        <legend>{Vocabulary.description}</legend>

        <Autocomplete
          freeSolo={false}
          disablePortal
          label={Vocabulary.templateName}
          options={descriptionTemplates}
          disabled={props.editButton}
          isOptionEqualToValue={(option, value) =>
            option?.templateName === value?.templateName
          }
          value={selectedTemplate}
          onChange={(event, value) => {
            if (value) {
              setValue(
                "descriere",
                replaceMacro(
                  value.html,
                  vehicleContext.state.vehicle,
                  cacheContext.cache
                )
              );
              setSelectedTemplate(value);
            } else {
              setValue("descriere", vehicleContext.state.vehicle.descriere);
              setSelectedTemplate(null);
            }
          }}
          getOptionLabel={(option) => option?.templateName}
          renderInput={(params) => (
            <TextField
              {...params}
              className={style.textField}
              id="descriptionTemplate"
              name="descriptionTemplate"
              label={Vocabulary.templateName}
            />
          )}
          style={{ marginBottom: 30 }}
        />

        <Controller
          name="descriere"
          control={control}
          defaultValue={formState.defaultValues?.descriere}
          render={({ field: { value, onChange } }) => (
            <GenericTemplate
              template={{
                html: value ? value : "",
              }}
              fromVehicle={true}
              handleChangeGenericTemplate={(value) => {
                onChange(value);
                setSelectedTemplate(null);
              }}
            />
          )}
        />
      </fieldset>
    );
  }
  return (
    <Box style={{ paddingBottom: "10rem" }}>
      <form
        method="get"
        id="formVehicleDetails"
        onSubmit={handleSubmit(onSubmit, onError)}
        noValidate
        ref={formRef}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            flexDirection: isMobile() ? "column" : "row",
          }}
        >
          {renderVehicleInformation()}
          {renderAdministrativeInfo()}
        </div>

        {renderEquipment()}
        {showDescription()}

        {/** NOTES */}
        <fieldset
          style={{
            marginTop: 50,
            borderRadius: 10,
            margin: 10,
            borderColor: "#d6d6d6",
            borderStyle: "solid",
            color: "#444",
          }}
        >
          <legend>{Vocabulary.notes}</legend>
          <Controller
            defaultValue={formState.defaultValues?.notite}
            name="notite"
            control={control}
            render={({ field: { value, onChange } }) => (
              <TextField
                style={{ width: "100%" }}
                onChange={onChange}
                value={value}
                disabled={props.editButton}
                id="notite"
                label={Vocabulary.notes}
                size="small"
                variant="standard"
                multiline
                rows={3}
              />
            )}
          />
        </fieldset>
      </form>

      {genericAutocompleteModel.open && (
        <GenericAutocompleteModal
          open={genericAutocompleteModel.open}
          onClose={() =>
            setGenericAutocompleteModel({
              ...genericAutocompleteModel,
              open: false,
              modelForFields: new AutocompleteModel(),
            })
          }
          title={genericAutocompleteModel.title}
          data={genericAutocompleteModel.data}
          id={genericAutocompleteModel.id}
          columns={genericAutocompleteModel.columns}
          validationSchema={genericAutocompleteModel.validationSchema}
          hiddenFieldsArray={genericAutocompleteModel.hiddenFieldsArray}
          modelForFields={genericAutocompleteModel.modelForFields}
          onEdit={(value, tableMeta) => {
            setGenericAutocompleteModel({
              ...genericAutocompleteModel,
              modelForFields: tableMeta.tableData[tableMeta.rowIndex],
            });
          }}
          onSucces={() => {
            setModels(brandId.brand_id, cacheContext.cache?.models);
            setValue(genericAutocompleteModel.id, null);
          }}
        ></GenericAutocompleteModal>
      )}
      <User
        open={openModalUser}
        onClose={() => setOpenModalUser(false)}
        title={Vocabulary.addNewUser}
      />
      <CompanyModal
        open={openModalCompany}
        title={Vocabulary.addNewCompany}
        onClose={() => setOpenModalCompany(false)}
      ></CompanyModal>
      <PostAdsIntegrationModal
        open={openIntegrationModal}
        setOpen={setOpenIntegrationModal}
        vehicle={Object.assign(vehicleContext.state.vehicle)}
        setVehicle={vehicleContext.updateVehicle}
        getDataRefresh={() =>
          setSiteLink(
            AUTODELRULATE_AD_LINK + vehicleContext.state.vehicle?.slug
          )
        }
      />
    </Box>
  );
}
