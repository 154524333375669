/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useEffect, useState } from "react";
import {
  Avatar,
  Badge,
  Button,
  ClickAwayListener,
  IconButton,
  Typography,
} from "@mui/material";
import style from "../Styles/vehicleOptions.module.css";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { urlEnum } from "../Utils/urlEnum";
import { styled } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import { getFileExtensionByFileType, isMobile } from "../Utils/utils";
import withRole from "../Utils/withRole";
import ImageNotSupportedIcon from "@mui/icons-material/ImageNotSupported";
import { VehicleContext } from "../Context/vehicleContext";
import FilePreview, { FileDataInfo } from "../Utils/FilePreview";
import CloseIcon from "@mui/icons-material/Close";
import { Vocabulary } from "../Utils/Vocabulary";
import GenericModal from "../Modal/GenericModal";
import { deleteData } from "../Services/deleteData";
import DuplicateVehicle from "./ProgressActions/DuplicateVehicle";
import PreviewImage from "./Generic/PreviewImage";
import { DropzoneArea } from "material-ui-dropzone";
import { toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";

function VehicleOptions(
  props: {
    info?: any;
    editButton?: boolean;
    handleEdit?: any;
    editText?: string;
    permission?: any;
  },
  location: any
) {
  const vehicleContext: any = useContext(VehicleContext);
  const [showOptions, setShowOptions]: any = useState(
    isMobile() ? true : false
  );
  const [avatarImage, setAvatarImage]: any = useState();
  const [openDeleteModal, setOpenDeleteModal]: any = useState(false);
  const [dropZone, openDropZone]: any = useState(false);
  location = useLocation();
  const navigate = useNavigate();
  const [vehicleMainImage, setVehicleMainImage]: any = useState();

  const test = location.pathname;
  const words = test.split("/");
  const id = words[words.length - 1];
  const path = words[2];

  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      top: 12,
      left: -51,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: "0 4px",
      color: "#fff",
    },
  }));

  /**
   *
   * @param id
   */
  const submitDeletedCar = (id: any) => {
    deleteVehicle(id);
  };

  /**
   *
   * @param id
   */
  async function deleteVehicle(id: any) {
    const url = `${urlEnum.vehicles}/${id}`;
    const res = (await deleteData(url)) as any;
    if (!res || res?.error) {
      return;
    } else {
      setOpenDeleteModal(false);
      navigate("/");
    }
  }

  useEffect(() => {
    if (vehicleContext.state.vehicle?.main_image)
      setAvatarImage({ file: vehicleContext.state.vehicle?.main_image });
    else
      setAvatarImage(
        vehicleContext?.state?.images && vehicleContext?.state?.images[0]
      );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleContext?.state?.images]);

  const getNumberOfBadge = (element: any) => {
    switch (element.id) {
      case "vehiclePhotos":
        return vehicleContext?.state?.images?.length > 0
          ? vehicleContext?.state?.images?.length
          : 0;

      case "vehicleDocuments":
        return vehicleContext?.state?.documents?.length > 0
          ? vehicleContext?.state?.documents?.length
          : 0;
      default:
        return element.slug
          ? vehicleContext?.state?.vehicle?.[element.slug]?.length > 0
            ? vehicleContext?.state?.vehicle?.[element.slug]?.length
            : 0
          : null;
    }
  };
  const [fileData, setFileData] = useState<FileDataInfo | null>(null);
  /**
   *
   */
  const openFile = async (event: any, filePath: string | null) => {
    const file = (event.currentTarget as HTMLElement)?.getAttribute(
      "vehicle-icon-file"
    );
    if (file) {
      setFileData({
        path: file,
        name: file?.split("/").pop(),
        type: getFileExtensionByFileType(file) || "",
      });
    } else {
      setFileData({
        path: undefined,
        name: "",
        type: "newFile",
      });
    }
  };

  /**
   *
   * @returns
   */
  const renderPreviewImage = () => {
    if (
      fileData?.type === "jpg" ||
      fileData?.type === "png" ||
      fileData?.type === "jpeg" ||
      fileData?.type === "webp" ||
      fileData?.type === "gif" ||
      fileData?.type === "svg"
    )
      return (
        <PreviewImage
          title={fileData?.name}
          open={fileData ? true : false}
          onClose={() => {
            setFileData(null);
          }}
          url={fileData?.path}
          isVehicleIcon={true}
          changeImage={() => openDropZone(true)}
        />
      );
    else if (fileData?.type === "newFile" && !dropZone) {
      openDropZone(true);
    }
    return null;
  };

  /**
   * save vehicle's main image in database
   */
  const saveVehicleMainImage = async () => {
    //nu mereu este contextul
    let vehicleId = vehicleContext.state.vehicle?.id;
    if (vehicleContext?.state?.images?.length > 0)
      vehicleId = vehicleContext?.state?.images[0]?.vehicul_id;
    if (vehicleContext?.state?.documents?.length > 0)
      vehicleId = vehicleContext?.state?.documents[0]?.vehicul_id;

    const newVehicle = await vehicleContext.saveMainImage(
      vehicleMainImage,
      vehicleId
    );

    openDropZone(false);
    setFileData(null);
    setAvatarImage({ file: newVehicle?.vehicle?.main_image });
    vehicleContext.updateVehicle(newVehicle?.vehicle);
  };

  /**
   * the dropzone component that lets you upload an image
   */
  const renderUploadNewImage = () => {
    return (
      <GenericModal
        open={dropZone}
        onClose={() => {
          openDropZone(false);
          setFileData(null);
        }}
        title={Vocabulary.addNewImages}
        maxWidth={isMobile() ? "100%" : "50%"}
        buttons={
          <Button
            variant="contained"
            style={{
              color: "white",
            }}
            color="primary"
            onClick={() => saveVehicleMainImage()}
          >
            {Vocabulary.save}
          </Button>
        }
        fullScreenOnMobile={false}
      >
        <DropzoneArea
          acceptedFiles={["image/*,application/*"]}
          // dropzoneClass={style.dropzoneAreaMainImage}
          maxFileSize={640000000} // 640 MB
          filesLimit={1}
          onChange={(files: File[]) => {
            if (files?.length) setVehicleMainImage(files[0]);
          }}
          showAlerts={true}
          dropzoneText={Vocabulary.dragAndDrop}
          getFileAddedMessage={() => Vocabulary.fileSuccessfullyAdded}
          getFileRemovedMessage={() => Vocabulary.fileSuccessfullyRemoved}
          alertSnackbarProps={{
            anchorOrigin: { vertical: "top", horizontal: "right" },
            autoHideDuration: 3000, // Duration before the snackbar automatically hides
          }}
        />
      </GenericModal>
    );
  };

  return (
    <ClickAwayListener
      key={showOptions}
      onClickAway={isMobile() ? () => setShowOptions(true) : () => null}
      style={{ zIndex: 6000 }}
    >
      <>
        <div>
          {isMobile() ? (
            <IconButton
              size="small"
              className={style.closeButton}
              aria-label="add"
              onClick={() => setShowOptions(!showOptions)}
            >
              {showOptions ? <DirectionsCarIcon /> : <CloseIcon />}
            </IconButton>
          ) : (
            <></>
          )}
          {isMobile() ? (
            <div
              className={!showOptions ? style.overlay : style.overlayHide}
              onClick={() => setShowOptions(!showOptions)}
            >
              <div
                className={
                  showOptions
                    ? style.containerOptionsHide
                    : style.containerOptions
                }
              >
                <div style={{ display: "flex" }}>
                  {avatarImage ? (
                    <Avatar
                      style={{
                        marginRight: 10,
                        marginLeft: -10,
                        borderRadius: 5,
                        marginBottom: 5,
                        cursor: "pointer",
                      }}
                      sx={{ width: 56, height: 56 }}
                      alt="Autodel"
                      src={`/api/${avatarImage?.file}`}
                      onClick={(event: any) =>
                        openFile(event, `/api/${avatarImage?.file}`)
                      }
                      vehicle-icon-file={`${urlEnum.vehicles}/${avatarImage?.file}`}
                    />
                  ) : (
                    <Avatar
                      style={{
                        marginRight: 10,
                        marginLeft: -10,
                        borderRadius: 5,
                        marginBottom: 5,
                        cursor: "pointer",
                      }}
                      sx={{ width: 56, height: 56 }}
                      onClick={(event: any) => {
                        if (vehicleContext?.state?.vehicle?.id)
                          openFile(event, null);
                        else {
                          toast.info("nu poti");
                        }
                      }}
                      vehicle-icon-file={null}
                    >
                      <ImageNotSupportedIcon></ImageNotSupportedIcon>
                    </Avatar>
                  )}

                  <div>
                    <Typography
                      style={{
                        fontSize: 17,
                        color: "#4d5e80",
                        fontWeight: 800,
                      }}
                    >
                      {vehicleContext?.state?.vehicle?.numeComplet
                        ? vehicleContext?.state?.vehicle?.numeComplet
                        : "Vehicul Nou"}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 14,
                        marginBottom: 15,
                        color: "#4d5e80",
                        fontWeight: 500,
                        textAlign: "center",
                      }}
                    >
                      {vehicleContext?.state?.vehicle?.vin
                        ? vehicleContext?.state?.vehicle?.vin
                        : "Adauga detalii vehicul"}
                    </Typography>
                  </div>
                </div>
                <div style={{ display: "flex", gap: "10px" }}>
                  {props?.info?.find((info: any) => {
                    if (info.delete) return true;
                  }) ? (
                    words[words.length - 1] !== "newVehicle" ? (
                      <Button
                        size="small"
                        variant="outlined"
                        onClick={(e: any) => {
                          setOpenDeleteModal(true);
                        }}
                        startIcon={<DeleteIcon />}
                      >
                        {Vocabulary.delete}
                      </Button>
                    ) : null
                  ) : null}

                  {props?.permission?.duplicateVehicle &&
                    id != "newVehicle" && <DuplicateVehicle />}
                </div>
                <Divider style={{ margin: "10px 0px" }} />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                    height: isMobile() ? "" : "100%",
                  }}
                >
                  {props?.info
                    ?.sort((a: any, b: any) =>
                      a?.orderNumber > b?.orderNumber ? 1 : -1
                    )
                    .map((element: any, key: any) => {
                      return (
                        props?.info[key] && (
                          <Link
                            onClick={() =>
                              isMobile() ? setShowOptions(!showOptions) : null
                            }
                            key={key}
                            className={
                              id === "newVehicle"
                                ? style.disableLink
                                : style.link
                            }
                            to={element.path + id}
                          >
                            <div
                              className={
                                path === element.id
                                  ? style.optionsSelected
                                  : style.option
                              }
                            >
                              <div className={style.icon}>{element.icon}</div>
                              <StyledBadge
                                showZero={false}
                                anchorOrigin={{
                                  vertical: "top",
                                  horizontal: "left",
                                }}
                                badgeContent={getNumberOfBadge(element)}
                                color="primary"
                              >
                                <Typography
                                  className={
                                    location.pathname === element.path
                                      ? style.selectedText
                                      : style.nonSelectedText
                                  }
                                >
                                  {element.name}
                                </Typography>
                              </StyledBadge>
                            </div>
                          </Link>
                        )
                      );
                    })}
                </div>
                <div id="button_portal"></div>
              </div>
            </div>
          ) : (
            <div style={{ margin: 20 }}>
              <div style={{ display: "flex" }}>
                {avatarImage ? (
                  <Avatar
                    style={{
                      marginRight: 10,
                      marginLeft: -10,
                      borderRadius: 5,
                      marginBottom: 5,
                      cursor: "pointer",
                    }}
                    sx={{ width: 75, height: 75 }}
                    alt="Autodel"
                    src={`/api/${avatarImage?.file}`}
                    onClick={(event: any) =>
                      openFile(event, `/api/${avatarImage?.file}`)
                    }
                    vehicle-icon-file={`${urlEnum.vehicles}/${avatarImage?.file}`}
                  />
                ) : (
                  <Avatar
                    style={{
                      marginRight: 10,
                      marginLeft: -10,
                      borderRadius: 5,
                      marginBottom: 5,
                      cursor: vehicleContext?.state?.vehicle?.id
                        ? "pointer"
                        : "inherit",
                    }}
                    sx={{ width: 75, height: 75 }}
                    onClick={(event: any) => {
                      if (vehicleContext?.state?.vehicle?.id)
                        openFile(event, null);
                      else {
                        toast.error(Vocabulary.completeVehicleDataMessage);
                      }
                    }}
                    vehicle-icon-file={null}
                  >
                    <ImageNotSupportedIcon></ImageNotSupportedIcon>
                  </Avatar>
                )}

                <div>
                  <Typography
                    style={{
                      fontSize: 17,
                      color: "#4d5e80",
                      fontWeight: 800,
                    }}
                  >
                    {vehicleContext?.state?.vehicle?.numeComplet
                      ? vehicleContext?.state?.vehicle?.numeComplet
                      : "Vehicul Nou"}
                  </Typography>
                  <Typography
                    style={{
                      fontSize: 14,
                      color: "#4d5e80",
                      fontWeight: 500,
                      textAlign: "center",
                    }}
                  >
                    {vehicleContext?.state?.vehicle?.vin
                      ? vehicleContext?.state?.vehicle?.vin
                      : "Adauga detalii vehicul"}
                  </Typography>
                </div>
              </div>
              <div style={{ display: "flex", gap: "10px" }}>
                {props?.info?.find((info: any) => {
                  if (info.delete) return true;
                }) ? (
                  words[words.length - 1] !== "newVehicle" ? (
                    <Button
                      size="small"
                      variant="outlined"
                      onClick={(e: any) => {
                        setOpenDeleteModal(true);
                      }}
                      startIcon={<DeleteIcon />}
                    >
                      {Vocabulary.delete}
                    </Button>
                  ) : null
                ) : null}
                {props?.permission?.duplicateVehicle && id != "newVehicle" && (
                  <DuplicateVehicle />
                )}
              </div>
              <Divider style={{ margin: "10px 0px" }} />
              <div
                style={{
                  marginTop: 2,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                  height: "100%",
                }}
              >
                {props?.info
                  ?.sort((a: any, b: any) =>
                    a?.orderNumber > b?.orderNumber ? 1 : -1
                  )
                  .map((element: any, key: any) => {
                    return (
                      props?.info[key] && (
                        <Link
                          onClick={() =>
                            isMobile() ? setShowOptions(!showOptions) : null
                          }
                          key={key}
                          className={
                            id === "newVehicle" ? style.disableLink : style.link
                          }
                          to={element.path + id}
                        >
                          <div
                            className={
                              path === element.id
                                ? style.optionsSelected
                                : style.option
                            }
                          >
                            <div className={style.icon}>{element.icon}</div>
                            <StyledBadge
                              showZero={false}
                              anchorOrigin={{
                                vertical: "top",
                                horizontal: "left",
                              }}
                              badgeContent={getNumberOfBadge(element)}
                              color="primary"
                            >
                              <Typography
                                className={
                                  location.pathname === element.path
                                    ? style.selectedText
                                    : style.nonSelectedText
                                }
                              >
                                {element.name}
                              </Typography>
                            </StyledBadge>
                          </div>
                        </Link>
                      )
                    );
                  })}
              </div>
              <div id="button_portal"></div>
            </div>
          )}

          <FilePreview
            key={
              vehicleContext?.state?.invoices?.OPInvoice.length +
              vehicleContext?.state?.invoices?.clientInvoice.length +
              vehicleContext?.state?.invoices?.providerInvoice.length +
              vehicleContext?.state?.invoices?.reservedDocuments.length +
              (vehicleContext?.state?.vehicle?.costuri_aditionale
                ? vehicleContext?.state?.vehicle?.costuri_aditionale?.length
                : 0) +
              (vehicleContext?.state?.documents
                ? vehicleContext?.state?.documents?.length
                : 0) +
              (vehicleContext?.state?.vehicle?.interventii_vopsitorie
                ? vehicleContext?.state?.vehicle?.interventii_vopsitorie.length
                : 0) +
              (vehicleContext?.state?.vehicle?.interventii_detailing
                ? vehicleContext?.state?.vehicle?.interventii_detailing.length
                : 0) +
              (vehicleContext?.state?.vehicle?.intrare_garantie
                ? vehicleContext?.state?.vehicle?.intrare_garantie.length
                : 0) +
              (vehicleContext?.state?.vehicle?.interventii_service
                ? vehicleContext?.state?.vehicle?.interventii_service.length
                : 0)
            }
          />
          <GenericModal
            open={openDeleteModal}
            onClose={() => setOpenDeleteModal(false)}
            title={Vocabulary.confirmDelete}
            fullScreenOnMobile={false}
            buttons={
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    setOpenDeleteModal(false);
                  }}
                >
                  {Vocabulary.no}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    submitDeletedCar(id);
                  }}
                >
                  {Vocabulary.yes}
                </Button>
              </div>
            }
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              {Vocabulary.askForDeleteVehicle}
            </div>
          </GenericModal>
        </div>
        {fileData?.type && renderPreviewImage()}
        {renderUploadNewImage()}
      </>
    </ClickAwayListener>
  );
}

export default withRole(VehicleOptions);
