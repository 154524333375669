/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import VehicleMainInfo from "./VehicleMainInfo";
// import VehicleMainInfoEmpty from "./VehicleMainInfoEmpty";
import { createPortal } from "react-dom";
import React, { useContext, useMemo } from "react";
import { getData } from "../Services/getData";
import { useLocation} from "react-router-dom";
import { urlEnum } from "../Utils/urlEnum";
import MobileNavigation from "./MobileNavigation";
import { Vocabulary } from "../Utils/Vocabulary";
//import { postData } from "../Services/postData";
import withRole from "../Utils/withRole";
import { VehicleContext } from "../Context/vehicleContext";
import { Button, Typography } from "@mui/material";
import { CacheContext } from "../Context/cacheContext";
import { VehiclesStatus } from "../Utils/autocompleteEnum";
import { RolesEnum } from "../Utils/Constants";
// import { getPermissions, takeEachRoute } from "./LoginPage";
import WarningIcon from "@mui/icons-material/Warning";
import style from "../Styles/vehicleDetails.module.css";
import { isMobile } from "../Utils/utils";
import { postData } from "../Services/postData";

// import { postData } from "../Services/postData";

function VehicleDetails(props: any) {
  const cacheContext: any = useContext(CacheContext);
  const vehicleContext: any = useContext(VehicleContext);
  //const navigate = useNavigate();
  const [domReady, setDomReady] = React.useState(false);
  const buttonPortal: any = document.getElementById("button_portal");
  const [editButton, setEditButton]: any = React.useState(true);
  const location = useLocation();
  const [editText, setEditText] = React.useState("");
  const roles = localStorage.getItem("roles") || "";
  const test = location.pathname;
  const words = test.split("/");
  const id = words[words.length - 1];
  const path = words[2];

  const soldStatusID = useMemo(() => {
    const sold = cacheContext.cache.vehicleStatus.find(
      (item: any) => item.nume === VehiclesStatus.sold
    );
    return sold?.id ? sold?.id : 0;
  }, [cacheContext.cache.vehicleStatus]);

  async function handleEdit(e: any) {
    setEditText("");
    const res = await getData(`${urlEnum.vehicles}/vehiclesEditing/${id}`);
    if (res && res.data.data.length > 0) {
      setEditText(
        `Utilizatorul ${
          res.data.data[0].userName
        } editeaza acest vehicul de ${Math.ceil(res.data.data[0].time)} minute.`
      );
    } else {
     // getVehicles();
      setEditButton(false);
      const userId = localStorage.getItem("useId");
      const response = await postData(`${urlEnum.vehicles}/vehiclesEditing`, {
        vehicul_id: id,
        user_id: userId,
      });
      console.log(response);
      setEditText("");
    }
  }

  // /**
  //  *
  //  * @returns
  //  */
  // async function getVehicles() {
  //   const url = `${urlEnum.vehicles}/${id}`;
  //   const res = (await getData(url)) as any;
  //   if (!res || res?.error) {
  //     const data: any = window.localStorage.getItem("permissions");
  //     const formatedData = data.split(",");
  //     navigate(getPermissions(takeEachRoute(formatedData)));
  //     return;
  //   }
  //   const vehicle = res?.data?.vehicles[0];
  //   if (
  //     isEmpty(vehicle?.descriere) &&
  //     !isEmpty(vehicleContext?.state.vehicle?.descriere)
  //   )
  //     vehicle.descriere = vehicleContext.state.vehicle.descriere;
  //   vehicleContext.updateVehicle(vehicle);
  //   (window as any).vehicleIdForDeletionFromVehiclesEditing =
  //     res?.data?.vehicles[0]?.id;
  //   vehicleContext.updateVehicleInvoices({
  //     clientInvoice: res.data.clientInvoice,
  //     providerInvoice: res.data.providerInvoice,
  //     OPInvoice: res.data.OPInvoice ? res.data.OPInvoice : [],
  //     reservedDocuments: res.data.reservedDocuments
  //       ? res.data.reservedDocuments
  //       : [],
  //   });
  //   vehicleContext.updateVehicleDocuments(res?.data?.documents);
  //   vehicleContext.updateVehicleImages(res?.data?.images);
  // }
  /**
   *
   * @returns
   */
  function showEdit_SaveButton() {
    return (
      <div>
        {editButton ? (
          <div style={{ marginTop: 50 }}>
            {editText !== "" ? (
              <div className={style.containerTable}>
                <WarningIcon style={{ marginRight: 12 }}></WarningIcon>
                <Typography style={{ fontSize: 10, fontWeight: 800 }}>
                  {editText}
                </Typography>
              </div>
            ) : null}
            <Button
              style={{
                color: "#fff",
                width: "100%",
                fill: "#1976d2 !important",
              }}
              color="secondary"
              variant="contained"
              onClick={(e: any) => {
                handleEdit(e);
              }}
            >
              {Vocabulary.edit}
            </Button>
            <div
              style={{
                display: "flex",
                textAlign: "center",
                marginTop: 10,
                color: "rgb(77, 94, 128)",
              }}
            ></div>
          </div>
        ) : (
          <Button
            style={{ color: "#fff", width: "100%", marginTop: 50 }}
            variant="contained"
            type="submit"
            form="formVehicleDetails"
          >
            {Vocabulary.save}
          </Button>
        )}
      </div>
    );
  }

  const handdleSubmitButton = (id: any) => {
    switch (id) {
      case "vehicleDashboard":
        return <></>;
      case "vehicleDetails":
        return (
          <div>
            {words[words.length - 1] === "newVehicle" ? (
              <Button
                style={{ color: "#fff", width: "100%", marginTop: 50 }}
                variant="contained"
                type="submit"
                form="formVehicleDetails"
              >
                {Vocabulary.save}
              </Button>
            ) : (
              <>
                {vehicleContext.state.vehicle?.status_id === soldStatusID ? (
                  <>
                    {(roles.includes(RolesEnum.admin) || roles.includes(RolesEnum.saleDepartment)) ? (
                      <>{showEdit_SaveButton()}</>
                    ) : null}
                  </>
                ) : (
                  <>{showEdit_SaveButton()}</>
                )}
              </>
            )}
          </div>
        );
      default:
        return null;
    }
  };

  React.useEffect(() => {
    setDomReady(true);
    if (words[words.length - 1] === "newVehicle") setEditButton(false);
  }, []);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ display: "flex" }}>
        <>
          {(vehicleContext.state.vehicle?.id || id === "newVehicle") && (
            <VehicleMainInfo
              editButton={editButton}
              sellBonus={props.info?.sellBonus}
              info={props.info}
            ></VehicleMainInfo>
          )}

          <div style={{ marginTop: 70 }}>
            {editText !== "" && isMobile() ? (
              <div className={style.containerTable}>
                <WarningIcon style={{ marginRight: 12 }}></WarningIcon>
                <Typography style={{ fontSize: 10, fontWeight: 800 }}>
                  {editText}
                </Typography>
              </div>
            ) : null}
            <MobileNavigation
              key={editButton}
              addNewLabel={Vocabulary.addNewUser}
              labels={[
                vehicleContext.state.vehicle?.numeComplet,
                vehicleContext.state.vehicle?.vin,
              ]}
              editButton={editButton}
              handleEdit={handleEdit}
            ></MobileNavigation>
          </div>
        </>

        {domReady && props?.info?.editVehicle
          ? createPortal(<div>{handdleSubmitButton(path)}</div>, buttonPortal)
          : null}
      </div>
    </div>
  );
}
export default withRole(VehicleDetails);
